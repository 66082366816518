import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const Navbar = () => {
    const { i18n } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    return (
        <div className="header">
            <div className="navbar">
                <Link className="link" to="/"> Home </Link>
                <Link id="camera" className="link" to="/camera"> Camera </Link>
                <Link className="link" to="/upload"> Upload </Link>
                <Link id="stickman" className="link" to="/stickman"> Stickman </Link>
            </div>
            <div className="langselect">
                <button className="langbutton" onClick={() => changeLanguage('nl')}>nl</button>
                <button className="langbutton" onClick={() => changeLanguage('fr')}>fr</button>
                <button className="langbutton" onClick={() => changeLanguage('en')}>en</button>
            </div>
        </div>        
    );
};