import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { debounce } from 'lodash';
import { ImageOutput } from './components/ImageOutput';
import toast, { Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

export const Artwork = () => {
    const [responseData, setResponseData] = useState([]);

    const [uploadedImage, setUploadedImage] = useState('');
    const [uploadedCropped, setUploadedCropped] = useState('');
    const [boxes, setBoxes] = useState([]);
    const [ogimgsize, setOgimgsize] = useState([]);
    const [resizedBoxes, setResizedBoxes] = useState(null);
    const [highlightedArea, setHighlightedArea] = useState(null);
    const [currPoseIndex, setCurrPoseIndex] = useState(0);
    const [metadata, setMetadata] = useState({});
    const [largeView, setLargeView] = useState(false);
    const [imageStyle, setImageStyle] = useState({
        maxWidth: '50vw',
        maxHeight: '30vh'
    })

    const { t } = useTranslation();

    const imageRef = useRef(null);

    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const filename = queryParams.get('filename');
    let loaded = false;

    const handleResize = debounce(() => {
        if (imageRef.current && imageRef.current.complete && boxes) {
            const width = imageRef.current.width;
            const height = imageRef.current.height;
            
            setResizedBoxes(boxes.map((box, index) => (
                [
                    Math.round(box.box[0] * width / ogimgsize[0]), 
                    Math.round(box.box[1] * height / ogimgsize[1]), 
                    Math.round(box.box[2] * width / ogimgsize[0]), 
                    Math.round(box.box[3] * height / ogimgsize[1])
                ]
            )));
        }        
    }, 400);

    window.addEventListener('resize', handleResize);
    useEffect(() => {
        if (boxes.length > 0 && ogimgsize.length > 0) {
            handleResize();
        }
    }, [boxes, ogimgsize]);

    useEffect(() => {
        if (boxes.length > 0 && responseData) {
            getPaintings(0, 5);
        }
    }, [boxes])

    useEffect(() => {
        if (boxes.length == 0 && uploadedImage)
            getBboxes();
    }, [uploadedImage])

    useEffect(() => {
        if (uploadedImage.length == 0 && !loaded) {
            getimage();
            loaded = true;
        }            
    }, [])

    useEffect(() => {
        handleResize();
    }, [imageStyle])

    const toggleView = () => {
        if (!largeView) {
            setImageStyle({
                maxHeight: '100vh',
                maxWidth: '100%'
            })
        } else {
            setImageStyle({
                maxHeight: '30vh',
                maxWidth: '50vw'
            })
        }        
        setLargeView(!largeView)
    }

    const getimage = () => {
        const formData = new FormData();
        formData.append('filename', filename);
        toast.promise(
            axios.post('/filenameToImage', formData),
            {
                loading: 'Getting artwork',
                success: 'Artwork found',
                error: 'Error searching artwork'
            }
        ).then((response) => {
                setUploadedImage("data:image/jpeg;base64," + response.data.originalimage);
                setMetadata(response.data.metadata);
            })
            .catch((error) => {
                console.error('Error loading artwork:', error);
            });
    }

    const getBboxes = () => {
        const formData = new FormData();
        formData.append('filename', filename);
        
        toast.promise(
            axios.post('/filenameToPoses', formData),
            {
                loading: 'Searching poses',
                success: 'Poses found',
                error: 'Error searching poses'
            }
        ).then((response) => {
                setBoxes(response.data.boxes);
                setOgimgsize(response.data.originalimagesize);
                const width = imageRef.current.width;
                const height = imageRef.current.height;
                setResizedBoxes(response.data.boxes.map((box, index) => (
                    [
                        Math.round(box.box[0] * width / response.data.originalimagesize[0]), 
                        Math.round(box.box[1] * height / response.data.originalimagesize[1]), 
                        Math.round(box.box[2] * width / response.data.originalimagesize[0]), 
                        Math.round(box.box[3] * height / response.data.originalimagesize[1])
                    ]
                )));
            })
            .catch((error) => {
                console.error('Error uploading image:', error);
            });
    };

    const getPaintings = (i, n) => {
        const formData = new FormData();
        formData.append('keypoints', boxes[i].keypoints);
        formData.append('weights', boxes[i].weights);
        formData.append('box', boxes[i].box);
        formData.append('n', n);
        toast.promise(
            axios.post('/poseToPaintings', formData), 
            {
                loading: 'Searching artworks',
                success: 'Artworks found',
                error: 'Error searching artworks'
            }
        ).then((response) => {
            setResponseData(response.data);
            cropImage(i);
        }).catch((error) => {
            console.error('Error searching artworks:', error);
        })
    };

    const cropImage = (i) => {
        const img = new Image();
        img.src = uploadedImage;
        img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = boxes[i].box[2] - boxes[i].box[0];
            canvas.height = boxes[i].box[3] - boxes[i].box[1];
            ctx.drawImage(img, boxes[i].box[0], boxes[i].box[1], canvas.width, canvas.height, 0, 0, canvas.width, canvas.height);
            setUploadedCropped(canvas.toDataURL('image/jpeg').split(',')[1]);
        };
    };

    return (
        <div>
            <Toaster />
            <div className='flexbox-container-upload'>
                {uploadedImage && (
                    <div style={{ position: 'relative' }}>
                        <map name="imageMap">
                            {resizedBoxes && (resizedBoxes.map((item, index) => (
                            <area
                                key={index}
                                shape="rect"
                                coords={item.join(',')}
                                alt={`Area ${index + 1}`}
                                onClick={() => {
                                    getPaintings(index, 5);
                                    setCurrPoseIndex(index);
                                    if (largeView) toggleView();
                                }}
                                onMouseEnter={() => setHighlightedArea(item)}
                                onMouseLeave={() => setHighlightedArea(null)}
                            />
                            )))}
                        </map>
                        {highlightedArea && (
                            <div
                            className='highlightedarea'
                            style={{
                                position: 'absolute',
                                top: highlightedArea[1],
                                left: highlightedArea[0],
                                width: highlightedArea[2] - highlightedArea[0],
                                height: highlightedArea[3] - highlightedArea[1],
                                border: 'dashed red 3px',
                                pointerEvents: 'none',
                            }}
                            />
                        )}
                        <img ref={imageRef} style={imageStyle} src={uploadedImage} alt="Uploaded Image" useMap="#imageMap" />
                    </div>
                )}
                <button onClick={toggleView}>{(largeView ? t('smaller view') : t('larger view'))}</button>
                <div className='table'>
                    <table>
                        <tbody>
                            {metadata && Object.entries(metadata).map(([key, value]) => (
                                <tr key={key}>
                                    <td><b>{key}</b></td>
                                    <td>{value}</td>
                                </tr>
                            ))} 
                        </tbody>                
                    </table>   
                </div>
                {responseData.length > 0 && (<div className='infobox'>
                    {boxes.length > 1 && <p>{t('info persons')}</p>} 
                    <p>{t('info left artwork')}</p>
                </div>)}                                    
            </div>
            {responseData && responseData.map && responseData.map((item, index) => (
                <ImageOutput key={index} outputImage1_annotated={item.painting_out_annotated} outputImage2_annotated={item.painting_out_cropped_annotated} outputImage1={item.painting_out} outputImage2={item.painting_out_cropped} outputImage3={uploadedCropped} title={item.title} simscore={item.similarity_score} filename={item.filename}/>
            ))}
            {responseData.length > 0 && <button className='loadmore' onClick={() => getPaintings(currPoseIndex, responseData.length + 5)}>{t("load more")}</button>}
        </div>
    );
};