import { render } from "@testing-library/react";
import React from "react";
import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';

export const Home = () => {
    const { t } = useTranslation();

    return (
        <div className="homepage">
            <h1>{t('home title')}</h1>
            <div className="homesection">
                <Markdown>{t('home welcome')}</Markdown>
            </div>
            <div className="homesection">
                <Markdown>{t('home works')}</Markdown>
            </div>
            <div className="homesection">
                <Markdown>{t('home works bts')}</Markdown>
            </div>
            <div className="homesection">
                <Markdown>{t('home focus art')}</Markdown>
            </div>
            <div className="homesection">
                <Markdown>{t('home who are we')}</Markdown>
            </div>
            <div className="homesection">
                <Markdown>{t('home disclaimer')}</Markdown>
            </div>
        </div>
    );
};