import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './i18n';
import { Navbar } from './Navbar';
import { Footer } from './Footer';
import { Home } from './pages/Home';
import { Camera } from './pages/Camera';
import { Upload } from './pages/Upload';
import { Stickman } from './pages/Stickman';
import { Artwork } from './pages/Artwork';

function App() {

    return (
        <div className='app-container'>
            <Router>
                <div className='bg-image'></div>
                <div className='content'>
                    <Navbar />
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/camera" element={<Camera />} />
                        <Route path="/upload" element={<Upload />} />
                        <Route path="/stickman" element={<Stickman />} />
                        <Route path="/artwork" element={<Artwork />} />
                    </Routes>
                </div>                             
                <Footer />
            </Router>
        </div>
    );
}

export default App