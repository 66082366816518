import React, { useState, useEffect } from 'react';
import { ReactP5Wrapper } from "react-p5-wrapper";
import axios from 'axios';
import { ImageOutputPNG } from './components/ImageOutputPNG';
import { drop, indexOf } from 'lodash';
import toast, { Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';


export const Stickman = () => {

    const [responseData, setResponseData] = useState([]);
    const [croppedPose, setCroppedPose] = useState('');
    const [keypoints, setKeypoints] = useState([[381, 179], [233, 180], [442, 245], [164, 240], [464, 323], [138, 319], [366, 335], [251, 336], [388, 431], [226, 433], [412, 556], [215, 559]]);
    const [vskeypoints, setVsKeypoints] = useState([[381, 179], [233, 180], [442, 245], [164, 240], [464, 323], [138, 319], [366, 335], [251, 336], [388, 431], [226, 433], [412, 556], [215, 559]]);
    const [box, setBox] = useState([138, 89.5, 464, 559]);
    const [skeypoints, setsKeypoints] = useState([]);
    const [sbox, setsBox] = useState([]);
    let beingDragged = -1;
    const [hidden, setHidden] = useState(new Set());

    const { t } = useTranslation();
    const poses = [
        {
            "label" : "arms_crossed",
            "keypoints" : [
              [ 256, 128 ], [ 155, 131 ], [ 271, 204 ], [ 148, 215 ], [ 187, 194 ],
              [ 230, 199 ], [ 246, 287 ], [ 181, 290 ], [ 248, 411 ], [ 168, 418 ],
              [ 246, 517 ], [ 161, 537 ]
            ],
            "bbox" : [ 124.64, 8.61, 287.41, 594.43 ]
          },
              {
                "label" : "horse_riding",
                "keypoints" : [
                  [ 331, 145 ], [ 271, 140 ], [ 341, 192 ], [ 261, 193 ], [ 355, 204 ],
                  [ 306, 203 ], [ 328, 232 ], [ 285, 233 ], [ 365, 280 ], [ 285, 280 ],
                  [ 365, 361 ], [ 250, 358 ]
                ],
                "bbox" : [ 228.63, 64.01, 370.74, 388.77 ]
              },
              {
                "label" : "jesus_cross",
                "keypoints" : [
                  [ 245, 143 ], [ 161, 146 ], [ 314, 124 ], [ 96, 127 ], [ 365, 97 ],
                  [ 44, 97 ], [ 244, 272 ], [ 194, 276 ], [ 235, 384 ], [ 198, 381 ],
                  [ 225, 486 ], [ 197, 483 ]
                ],
                "bbox" : [ 6.13, 63.11, 404.32, 546.98 ]
              },
              {
                "label" : "kneeling_praying_left",
                "keypoints" : [
                  [ 185, 181 ], [ 114, 183 ], [ 188, 291 ], [ 85, 292 ], [ 117, 233 ],
                  [ 101, 229 ], [ 170, 378 ], [ 115, 374 ], [ 168, 531 ], [ 118, 512 ],
                  [ 268, 487 ], [ 239, 478 ]
                ],
                "bbox" : [ 71.32, 75.56, 315.25, 551.29 ]
              },
              {
                "label": "kneeling_praying_right",
                "keypoints": [
                    [279, 184],[208, 181],[308, 293],[204, 292],[290, 230],
                    [274, 234],[278, 376],[222, 381],[274, 512],
                    [223, 532],[153, 479],[123, 489]
                ],
                "bbox": [77.01, 75.90, 320.20, 551.50]
            },
            // {
            //     "label" : "laying_down_left",
            //     "keypoints" : [
            //       [ 201, 210 ], [ 137, 280 ], [ 291, 219 ], [ 55, 308 ], [ 291, 278 ],
            //       [ 83, 228 ], [ 333, 249 ], [ 322, 294 ], [ 418, 175 ], [ 455, 303 ],
            //       [ 507, 289 ], [ 502, 301 ]
            //     ],
            //     "bbox" : [ 45.24, 162.51, 582.45, 335.93 ]
            //   },
              {
                "label" : "laying_down_right",
                "keypoints" : [
                  [ 462, 280 ], [ 399, 210 ], [ 546, 310 ], [ 310, 219 ], [ 516, 227 ],
                  [ 308, 278 ], [ 278, 294 ], [ 266, 248 ], [ 149, 303 ], [ 182, 175 ],
                  [ 96, 303 ], [ 91, 289 ]
                ],

                "bbox" : [ 45.24, 162.51, 582.45, 335.93 ]
              },
              {
                "label" : "sitting_left",
                "keypoints" : [
                  [ 386, 177 ], [ 284, 177 ], [ 425, 295 ], [ 256, 283 ], [ 329, 315 ],
                  [ 180, 299 ], [ 360, 348 ], [ 283, 331 ], [ 259, 335 ], [ 153, 321 ],
                  [ 225, 524 ], [ 115, 510 ]
                ],
                "bbox" : [ 36.31, 27.55, 446.89, 575.98 ]
              },
              {
                "label" : "sitting_right",
                "keypoints" : [
                  [ 196, 177 ], [ 94, 177 ], [ 225, 283 ], [ 56, 295 ], [ 301, 300 ],
                  [ 149, 315 ], [ 198, 330 ], [ 120, 349 ], [ 328, 322 ], [ 222, 334 ],
                  [ 365, 509 ], [ 255, 523 ]
                ],
                "bbox" : [ 35.16, 26.76, 446.98, 574.94 ]
              },
              {
                "label" : "standing_praying",
                "keypoints" : [
                  [ 234, 118 ], [ 135, 120 ], [ 252, 193 ], [ 119, 197 ], [ 199, 150 ],
                  [ 170, 150 ], [ 214, 283 ], [ 152, 283 ], [ 213, 400 ], [ 149, 401 ],
                  [ 208, 504 ], [ 146, 504 ]
                ],
                "bbox" : [ 100.40, 22.74, 270.50, 548.70 ]
              },
          {
            "label": "t_pose",
                  "keypoints": [
                      [287, 142], [197, 142],[355, 142],
                      [120, 139],[417, 132],[56, 133],[262, 292],
                      [214, 290],[251, 407],[214, 404],[239, 513],
                      [220, 513]
                  ],
                  "bbox": [13.02, 48.46, 466.88, 550.39]
          },
          
    
            
    
        // Add more poses as needed
    ];
    

    function sketch(p5) {
        let kps = [];
        let vskps = [];
        let body = [[0, 1], [0, 6], [1, 7], [6, 7]]
        let leftarm = [[0, 2], [2, 4]]
        let rightarm = [[1, 3], [3, 5]]
        let leftleg = [[6, 8], [8, 10]]
        let rightleg = [[7, 9], [9, 11]]

        p5.setup = () => {
            p5.createCanvas(600, 600);
            let canvas = document.querySelector('canvas');
            canvas.addEventListener('contextmenu', function(event) {
              event.preventDefault();
            });
            kps = keypoints;
            vskps = vskeypoints;
        }

        p5.mousePressed = () => {
            if (p5.mouseButton === p5.LEFT) {
                for (let i = 0; i < kps.length; i++) {
                    let d = p5.dist(p5.mouseX, p5.mouseY, vskps[i][0], vskps[i][1])
                    if (d < 15) {
                        beingDragged = i;
                        break;
                    }
                }
            } else if (p5.mouseButton === p5.RIGHT) {
                for (let i = 0; i < kps.length; i++) {
                    let d = p5.dist(p5.mouseX, p5.mouseY, vskps[i][0], vskps[i][1])
                    if (d < 15) {
                        if (hidden.has(i)) {
                            hidden.delete(i);
                            setHidden(hidden);
                        } else {
                            hidden.add(i);
                            if (i == 2 || i == 3 || i == 8 || i == 9) {
                                hidden.add(i + 2);
                            }
                            setHidden(hidden);
                        }
                        break;
                    }
                }
            }            
        }
    
        p5.mouseReleased = () => {
            if (beingDragged != -1 || p5.mouseButton == p5.RIGHT) {
                beingDragged = -1;
                let tempkps = []
                kps.map((kp, index) => {
                    if (hidden.has(index)) {
                        tempkps.push([0, 0]);
                    } else {
                        tempkps.push(kp);
                    }
                });
                setVsKeypoints([...vskeypoints]);
                setKeypoints([...tempkps]);
                let xs = []
                let ys = []
                for (let i = 0; i < kps.length; i++) {
                    if (hidden.has(i)) continue;
                    xs.push(kps[i][0]);
                    ys.push(kps[i][1]);
                }
                setBox([p5.min(xs)-8, p5.min(p5.min(ys)-8, (ys[0] + ys[1]) / 2 - 90), p5.max(xs)+8, p5.max(ys)+8])
            }
            
        }

        const drawpart = (part) => {
            for (let i = 0; i < part.length; i++) {
                if (hidden.has(part[i][0]) || hidden.has(part[i][1])) continue;
                p5.line(vskps[part[i][0]][0], vskps[part[i][0]][1], vskps[part[i][1]][0], vskps[part[i][1]][1]);
            }
        }

        p5.draw = () => {
            p5.clear();
            p5.background(0, 0, 0, 30);

            if (beingDragged != -1) {
                kps[beingDragged] = [p5.min(p5.width-8, p5.max(8, p5.mouseX)), p5.min(p5.height-8, p5.max(8, p5.mouseY))];
                vskps[beingDragged] = kps[beingDragged];
            }

            p5.push();
            p5.strokeWeight(5);

            p5.stroke(0);
            drawpart(body);
            p5.stroke(255, 0, 0);
            drawpart(leftarm);
            p5.stroke(0, 0, 255);
            drawpart(rightarm);
            p5.stroke(255, 255, 0);
            drawpart(leftleg);
            p5.stroke(0, 255, 0);
            drawpart(rightleg);
            p5.pop();

            for (let i = 0; i < kps.length; i++) {
                if (hidden.has(i))
                    p5.fill(128);
                else
                    p5.fill(255);
                p5.ellipse(vskps[i][0], vskps[i][1], 15, 15);
            }
        }
    }

    const getPaintings = async (n, more) => {
        const formData = new FormData();
        if (more) {
            formData.append('keypoints', skeypoints);
            formData.append('box', sbox);
        } else {
            formData.append('keypoints', keypoints);
            formData.append('box', box);
        }
        formData.append('n', n);
        
        toast.promise(
            axios.post('/poseToPaintings', formData), 
            {
                loading: 'Searching poses',
                success: 'Poses found',
                error: 'Error uploading pose'
            }
        ).then((response) => {
            setResponseData(response.data);
        }).catch((error) => {
            console.error('Error uploading pose:', error);
        })
    }
    const changePose = () => {
        const pose = document.getElementById('dropdown').value;
        for (let i = 0; i < poses.length; i++) {
            if (poses[i].label == pose) {
                setKeypoints(poses[i].keypoints);
                setVsKeypoints(poses[i].keypoints);
                setBox(poses[i].bbox);
                setsBox(poses[i].bbox);
            }
        }
    }

    const handleClick = () => {
        const canvas = document.getElementsByTagName('canvas')[0]; // Get the canvas element
        const context = canvas.getContext('2d');
        const imageData = context.getImageData(box[0], box[1], box[2] - box[0], box[3] - box[1]); // Get the image data of the portion between (50, 60) and (350, 440)
        
        const tempCanvas = document.createElement('canvas'); // Create a temporary canvas
        tempCanvas.width = box[2] - box[0]; // Set the width of the temporary canvas
        tempCanvas.height = box[3] - box[1]; // Set the height of the temporary canvas
        const tempContext = tempCanvas.getContext('2d');
        
        tempContext.putImageData(imageData, 0, 0); // Put the image data onto the temporary canvas
        
        const imgData = tempCanvas.toDataURL('image/png'); // Convert the temporary canvas to a JPEG base64 encoded string
        setCroppedPose(imgData.split(',')[1]); // Update the responseData state
        setsKeypoints(keypoints);
        setsBox(box);
        getPaintings(5, false);
    }
    return (
        <>
            <Toaster />
            <div className='stickflex'>
                <div className="stickman-container">
                    <ReactP5Wrapper sketch={sketch} />
                </div>
                <select id='dropdown' onChange={changePose} >
                    {poses.map((pose, index) => (
                        <option key={index} value={pose.label}>{t(pose.label)}</option>
                    ))}
                </select>
                <div className='search-container'>
                    <button onClick={handleClick}>{t('search')}</button>
                </div>
                <div className='infobox'>
                    <p>{t('stickman h')}</p>
                    {responseData.length > 0 && (<p>{t('info left artwork')}</p>)}                    
                </div>
            </div>
            {responseData.map((item, index) => (
                <ImageOutputPNG key={index} outputImage1_annotated={item.painting_out_annotated} outputImage2_annotated={item.painting_out_cropped_annotated} outputImage1={item.painting_out} outputImage2={item.painting_out_cropped} outputImage3={croppedPose} title={item.title} simscore={item.similarity_score} filename={item.filename}/>
            ))}
            {responseData.length > 0 && <button className='loadmore' onClick={() => getPaintings(responseData.length + 5, true)}>{t("load more")}</button>}
        </>
    );
}