import React, { useState, useRef, useEffect } from 'react';
import Webcam from 'react-webcam';
import axios from 'axios';
import { debounce } from 'lodash';
import { ImageOutput } from './components/ImageOutput';
import toast, { Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

export const Camera = () => {
    const webcamRef = useRef(null);

    const [responseData, setResponseData] = useState([]);

    const [uploadedImage, setUploadedImage] = useState('');
    const [uploadedCropped, setUploadedCropped] = useState('');
    const [boxes, setBoxes] = useState([]);
    const [ogimgsize, setOgimgsize] = useState([]);
    const [resizedBoxes, setResizedBoxes] = useState(null);
    const [highlightedArea, setHighlightedArea] = useState(null);
    const [currPoseIndex, setCurrPoseIndex] = useState(0);
    const [countdown, setCountdown] = useState(5);
    const [isTakingPicture, setIsTakingPicture] = useState(false);

    const { t } = useTranslation();

    const imageRef = useRef(null);

    const handleResize = debounce(() => {
        if (imageRef.current && imageRef.current.complete && boxes) {
            const width = imageRef.current.width;
            const height = imageRef.current.height;
            
            setResizedBoxes(boxes.map((box, index) => (
                [
                    Math.round(box.box[0] * width / ogimgsize[0]), 
                    Math.round(box.box[1] * height / ogimgsize[1]), 
                    Math.round(box.box[2] * width / ogimgsize[0]), 
                    Math.round(box.box[3] * height / ogimgsize[1])
                ]
            )));
        }
        
    }, 400);

    window.addEventListener('resize', handleResize);

    useEffect(() => {
        if (imageRef.current && boxes) {
            const width = imageRef.current.width;
            const height = imageRef.current.height;
        }
    }, [uploadedImage]);

    useEffect(() => {
        if (boxes.length > 0) {
            getPaintings(0, 5);
        }
    }, [boxes]);

    useEffect(() => {
        let timerId;

        if (isTakingPicture && countdown > 0) {
            timerId = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
        }

        return () => {
            clearInterval(timerId);
        };
    }, [isTakingPicture, countdown]);

    const getBboxes = (base64image) => {
        const formData = new FormData();
        formData.append('file', base64image.split(',')[1]);
        
        toast.promise(
            axios.post('/imageToPoses', formData),
            {
                loading: 'Searching poses',
                success: 'Poses found',
                error: 'Error searching poses'
            }
        ).then((response) => {
                setBoxes(response.data.boxes);
                setOgimgsize(response.data.originalimagesize);
                const width = imageRef.current.width;
                const height = imageRef.current.height;
                setResizedBoxes(response.data.boxes.map((box, index) => (
                    [
                        Math.round(box.box[0] * width / response.data.originalimagesize[0]), 
                        Math.round(box.box[1] * height / response.data.originalimagesize[1]), 
                        Math.round(box.box[2] * width / response.data.originalimagesize[0]), 
                        Math.round(box.box[3] * height / response.data.originalimagesize[1])
                    ]
                )));
            })
            .catch((error) => {
                console.error('Error uploading image:', error);
            });
    };

    const getPaintings = (i, n) => {
        const formData = new FormData();
        formData.append('keypoints', boxes[i].keypoints);
        formData.append('box', boxes[i].box);
        formData.append('n', n);
        toast.promise(
            axios.post('/poseToPaintings', formData),
            {
                loading: 'Searching paintings',
                success: 'Paintings found',
                error: 'Error searching paintings'
            }
        ).then((response) => {
            setResponseData(response.data);
            cropImage(i);
        }).catch((error) => {
            console.error('Error uploading pose:', error);
        })
    };

    const cropImage = (i) => {
        const img = new Image();
        img.src = uploadedImage;
        img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = boxes[i].box[2] - boxes[i].box[0];
            canvas.height = boxes[i].box[3] - boxes[i].box[1];
            ctx.drawImage(img, boxes[i].box[0], boxes[i].box[1], canvas.width, canvas.height, 0, 0, canvas.width, canvas.height);
            setUploadedCropped(canvas.toDataURL('image/jpeg').split(',')[1]);
        };
    };

    // Function to handle taking a picture
    const takePicture = () => {    
        setIsTakingPicture(true);
        setCountdown(5);

        setTimeout(() => {
            const imageSrc = webcamRef.current.getScreenshot();
            setUploadedImage(imageSrc);
            getBboxes(imageSrc);
            setIsTakingPicture(false);
        }, 5000)
    };

    // Function to upload the image to the server
    const uploadImage = (base64Image) => {
        const formData = new FormData();
        formData.append('file', base64Image.split(',')[1]);

        axios.post('/uploadimage', formData)
            .then((response) => {
                setResponseData(response.data);
            })
            .catch((error) => {
                console.error('Error uploading image:', error);
            });
    };

    return (
        <div>
            <Toaster />
            {!uploadedImage && (<div className='flexbox-container'>
                <div className='camera'>
                   <Webcam
                    className='webcam'
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    mirrored={true}
                    height={100+'%'}
                    width={100+'%'}                  
                    /> 
                </div>                
                <button className='camerabutton' onClick={takePicture} disabled={isTakingPicture}>
                {isTakingPicture ? `${countdown}` : t('picture')}
                </button>
            </div>)}

            {uploadedImage && (
                    <div className='flexbox-container-upload'>
                        <div style={{ position: 'relative' }}>
                            <map name="imageMap">
                                {resizedBoxes && (resizedBoxes.map((item, index) => (
                                <area
                                    key={index}
                                    shape="rect"
                                    coords={item.join(',')}
                                    alt={`Area ${index + 1}`}
                                    onClick={() => {
                                        getPaintings(index, 5);
                                        setCurrPoseIndex(index);
                                    }}
                                    onMouseEnter={() => setHighlightedArea(item)}
                                    onMouseLeave={() => setHighlightedArea(null)}
                                />
                                )))}
                            </map>
                            {highlightedArea && (
                                <div
                                className='highlightedarea'
                                style={{
                                    position: 'absolute',
                                    top: highlightedArea[1],
                                    left: highlightedArea[0],
                                    width: highlightedArea[2] - highlightedArea[0],
                                    height: highlightedArea[3] - highlightedArea[1],
                                    border: 'dashed red 3px',
                                    pointerEvents: 'none',
                                }}
                                />
                            )}
                            <img ref={imageRef} className="imageToChooseFrom" src={uploadedImage} alt="Uploaded Image" useMap="#imageMap" />
                        </div>
                        <button onClick={() => {
                            setUploadedImage('');
                            setBoxes([]);
                            setResizedBoxes(null);
                        }}>{t('new picture')}</button>
                        {responseData.length > 0 && (<div className='infobox'>
                            {boxes.length > 1 && <p>{t('info persons')}</p>}
                            <p>{t('info left artwork')}</p>
                        </div>)}
                    </div>
                )}

            {responseData.map((item, index) => (
                <ImageOutput key={index} outputImage1_annotated={item.painting_out_annotated} outputImage2_annotated={item.painting_out_cropped_annotated} outputImage1={item.painting_out} outputImage2={item.painting_out_cropped} outputImage3={uploadedCropped} title={item.title} simscore={item.similarity_score} filename={item.filename}/>
            ))}
            {responseData.length > 0 && <button className='loadmore' onClick={() => getPaintings(currPoseIndex, responseData.length + 5)}>{t("load more")}</button>}
        </div>
    );
};