import { ReactComponent as UGentSvg } from './assets/ugent_logo.svg';
import { ReactComponent as KMSKBSvg } from './assets/kmskb_logo.svg';
import { useTranslation } from 'react-i18next';


export const Footer = () => {
    const { t } = useTranslation();

    return (
        <div className="footer">
            <a href='https://fine-arts-museum.be/' target='_blank'><KMSKBSvg style={{ maxHeight: '45px', maxWidth: '255px' }}/></a>            
            <p>{t("home title")}</p>
            <a href='https://www.ugent.be/' target="_blank"><UGentSvg style={{ maxHeight: '45px' }}/></a>                       
        </div>
    );
};