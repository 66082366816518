import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export const ImageOutputPNG = (props) => {
    const { outputImage1, outputImage2, outputImage3, outputImage1_annotated, outputImage2_annotated, title, simscore, filename } = props;

    const [skeletonShown, setSkeletonShown] = useState(false);

    const { t } = useTranslation();

    return (
        <div className='outputimagescontainer'>
            <a className='outputimage' href={`/artwork?filename=${filename}`}>
                <img className='outputimageitself' src={`data:image/jpeg;base64,${skeletonShown ? outputImage1_annotated : outputImage1}`} alt="Output 1" />
            </a>
            <div className='outputimage'>
                <img className='outputimageitself' src={`data:image/jpeg;base64,${skeletonShown ? outputImage2_annotated : outputImage2}`} alt="Output 2" />
            </div>
            <div className='outputimage'>
                <img className='outputimageitself' src={`data:image/png;base64,${outputImage3}`} alt="Output 3" />
            </div>
            <h2>{title}</h2>
            <h2>{simscore}</h2>
            <h2>
                <input
                    type="checkbox"
                    checked={skeletonShown}
                    onChange={() => setSkeletonShown(!skeletonShown)}
                    style={{width:'20px', height:'20px', margin: '5px'}}
                ></input>
                {t('skeleton')}
            </h2>            
        </div>
    );
};