import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
"home title": "Pose and Find",
"home welcome": `
## Welcome to Pose and Find!

Here, you can search through the artworks of the *[Royal Museums of Fine Arts of Belgium](https://fine-arts-museum.be/en)* based on the poses assumed by the people in them. Pose for the camera, provide us with a pose through a photo, or use the stickman. Then, discover all the artworks where a person's pose matches yours. Recognize yourself in beautiful artworks in a completely new way!
`,
"home works": `
## How does it work?

- **Step 1**: Choose a method to offer us a pose.
    - *Camera*. This allows you to use your camera to take a live photo of yourself and possible others, in which you assume a specific pose.
    - *Upload*. With this method you can use a photo that already exists on your device. The photo must contain at least one person assuming the pose which you want to search for.
    - *Stickman*. You can change the drawn figure's pose by dragging its arms, legs and torso.
- **Step 2**: Load the pose using the chosen method. If there are multiple people in the uploaded photo, you can choose the pose of a single person by clicking on that person. Now simply wait for the poses to be matched.
- **Step 3**: View the result! Now you can scroll through the artworks where a person assumes the same pose as you. You can learn more about the artwork and artist by clicking on the artwork!

For those who can't get enough, you can search for and compare the poses of other people in the artwork you selected by clicking on the person.
   
Enjoy!
`,
"home works bts":`
## How does it work behind the scenes?

Behind the scenes, this website relies on a large database that contains all the artworks from the Royal Museums of Fine Arts of Belgium.
In addition to details such as the artist, year, etc., each artwork also includes information which poses they are assuming.
When you offer us a photo, image, or stickman, we determine the pose the person assumes.
We used an existing pose estimation model called MMPose to achieve this.

Finally, we compare the detected pose with the stored poses and actions in the artworks using a custom-written matching algorithm.
The artworks with the best matches get returned as results for you to view.
`,
"home focus art":`
## Focus on Art

The goal of our project is to make searching for artworks easier, more enjoyable, and more accessible. Since text and words often fall short in this regard, we are pleased to offer this alternative method.
Additionally, our application can enrich a visit to the museum by providing interaction with the artworks in a fun and seamless manner.

We also want to use this website to highlight the artworks themselves. By providing information about each artwork, we hope to make this website an educational experience. In doing so, we hope to inspire a stronger interest in and a better understanding of the artworks.
`, 
"home who are we": `
## Who are we?

We are a team of 4 students (Liam, Besar, Toon, and Wannes) studying Computer Science Engineering at Ghent University.
We conducted this project as part of the interdisciplinary course in our third bachelor year.
Throughout the development process, we received valuable assistance from Dieter de Witte (Supervisor), Ravi Khatri (Supervisor), and Kenzo Milleville (Supervisor) from IDLab. A special thanks goes to the Royal Museums of Fine Arts of Belgium for their support of this project!
`,
"home disclaimer": `
## Disclaimer:

The information you provide us through a live photo, uploaded image, or the stickman is not stored and cannot be reused in any way.
`, 
"load more": "Load more",
"info persons": "Click on a different person to look up another pose. If no red rectangle appears when hovering over a person, it means that the AI model has not detected a person here.",
"info left artwork": "Click on the complete artworks (on the left) to find out more about them, and to search for the poses in this work.",
"search": "Search",
"upload": "Upload image/picture",
"new picture": "Take a new picture",
"picture": "Take picture",
"stickman h": "Right-click a joint to exclude it from the search.",
"larger view": "Larger view",
"smaller view": "Smaller view",
"skeleton": "Skeleton",
"arms_crossed": "Arms crossed",
"horse_riding": "Horseback riding",
"jesus_cross": "Jesus on the Cross",
"kneeling_praying_left": "Kneeling praying (left)",
"kneeling_praying_right": "Kneeling praying (right)",
"laying_down_left": "Laying down (left)",
"laying_down_right": "Laying down (right)",
"sitting_left": "Sitting (left)",
"sitting_right": "Sitting (right)",
"standing_praying": "Standing praying",
"t_pose": "T-pose"
    },
  },
  fr: {
    translation: {
"home title": "Posez et Trouvez",
"load more": "Chargez plus", 
"home welcome": `
## Bienvenue chez Posez et Trouvez!

Sur ce site, vous pouvez rechercher une œuvre parmi toutes les œuvres d'art des *[Musées royaux des Beaux-Arts de Belgique](https://fine-arts-museum.be/fr)* en fonction des poses adoptées par les personnes représentées. Posez devant l'appareil photo ou proposez-nous une pose à partir d'une photo ou en bougeant stickman. Trouvez ensuite toutes les œuvres d'art où figure une personne dont la pose correspond à la vôtre. Reconnaissez-vous dans de belles œuvres d'art d'une manière tout à fait nouvelle !
`,
"home works": `
## Comment ça marche ?

- **Étape 1** : Choisissez une méthode pour nous proposer une pose.
    - *Camera*. Cela vous permet de prendre une photo vous montrant, vous et éventuellement d'autres personnes, en train de prendre une pose spécifique.
    - *Upload*. Vous pouvez nous proposer une photo déjà existante sur votre appareil. La photo doit contenir une ou plusieurs personnes prenant une pose.    
    - *Stickman*.  Il s'agit d'un bonhomme allumette dont vous pouvez ajuster la pose en déplaçant vous-même ses bras, ses jambes et son torse.
- **Étape 2** : Chargez la pose en utilisant la méthode choisie. S'il y a plusieurs personnes dans la photo téléchargée, vous pouvez choisir la pose d'une seule personne en cliquant sur cette personne. Il ne vous reste plus qu'à attendre les résultats.
- **Étape 3** : Découvrez le résultat ! Vous pouvez maintenant découvrir les œuvres d'art dans où une personne adopte la même pose que vous. Apprenez-en davantage sur l'œuvre et l'artiste en cliquant sur le tableau !

Et pour les plus curieux : recherchez et comparez les poses d'autres personnes sur l'œuvre d'art sélectionnée en cliquant sur cette personne.
`,
"home works bts": `
## Comment cela fonctionne-t-il en coulisses ?

Ce site web utilise une vaste base de données qui contient toutes les œuvres d'art des Musées 
royaux des Beaux-Arts de Belgique. En plus des détails tels que l'artiste, l'année, etc. Chaque 
tableau contient également des informations sur les poses qu'elles prennent. Lorsque vous nous proposez une photo, une 
image ou le bonhomme allumette, nous déterminons la pose que prend la personne. Pour ce faire, nous utilisons un modèle d'estimation de la pose préexistant 
appelé MMPose.

Enfin, nous comparons la pose et l'action détectées avec celles stockées dans les œuvres d'art 
à l'aide d'un algorithme qui nous avons conçu. Les tableaux présentant les meilleures 
correspondances vous sont ensuite renvoyés et vous pouvez consulter les résultats.
`,
"home focus art": `
## L'art sous les projecteurs

L'objectif de notre projet est de rendre la recherche de œuvres d'art plus facile, plus agréable et 
plus accessible. Comme le texte et les mots s'avèrent souvent insuffisants à cet égard, nous 
sommes heureux d'offrir cette méthode alternative. En outre, notre application peut enrichir une 
visite au musée en permettant d'interagir avec les œuvres d'art d'une manière amusante et 
rapide.

Nous souhaitons également utiliser ce site web pour mettre en valeur les œuvres d'art. En 
fournissant des informations sur chaque tableau, nous espérons faire de ce site web une 
expérience éducative. Nous espérons ainsi susciter un plus grand intérêt pour les œuvres d'art 
et une meilleure compréhension de celles-ci.
`,
"home who are we": `
## Qui sommes-nous ?

Nous sommes une équipe de 4 étudiants (Liam, Besar, Toon et Wannes) faisant des études 
d'ingénieur civil à l'Université de Gand. Nous avons mené ce projet dans le cadre du cours 
interdisciplinaire de notre troisième année de bachelier. Tout au long du processus de 
développement, nous avons bénéficié de l'aide précieuse de Dieter de Witte (superviseur), Ravi 
Khatri (superviseur) et Kenzo Milleville (superviseur) d'IDLab. Nous remercions tout 
particulièrement les Musées royaux des beaux-arts de Belgique qui ont soutenu ce projet !
`,
"home disclaimer": `
## Disclaimer :

Les informations que vous nous fournissez par le moyen de l'appareil photo, d'une image 
téléchargée ou du stickman ne sont pas stockées et ne peuvent être réutilisées de quelque 
manière que ce soit.
`,
"info persons": "Cliquez sur une autre personne pour chercher une pose différente. Si aucun contour rouge n'apparaît lorsque vous passez au-dessus d'une personne, cela signifie que le modèle d'IA n'a pas détecté de personne à cet endroit.",
"info left artwork": "Cliquez sur les œuvres d'art originales (à gauche) pour en apprendre plus, et pour chercher les poses dans ces œuvres.",
"search": "Chercher",
"upload": "Télécharger image/photo",
"new picture": "Prendre une nouvelle photo",
"picture": "Prendre une photo",
"stickman h": "Cliquez avec le bouton droit de la souris sur une articulation pour l'ignorer dans la recherche.",
"larger view": "Vue plus large",
"smaller view": "Vue plus petite",
"skeleton": "Squelette",
"arms_crossed": "Bras croisés",
"horse_riding": "Équitation",
"jesus_cross": "Jésus sur la croix",
"kneeling_praying_left": "S'agenouiller pour prier (gauche)",
"kneeling_praying_right": "S'agenouiller pour prier (droite)",
"laying_down_left": "Couché (à gauche)",
"laying_down_right": "Couché (à droite)",
"sitting_left": "Assis (à gauche)",
"sitting_right": "Assis (à droite)",
"standing_praying": "Prière debout",
"t_pose": "Pose T"
    },
  },
  nl: {
    translation: {
"home title": "Poseer en Vind",
"home welcome": `
## Welkom bij Poseer en Vind!

Hier kan u zelf doorheen alle kunstwerken van de *[Koninklijke Musea voor Schone Kunsten van België](https://fine-arts-museum.be/nl)* zoeken op basis van de poses die de afgebeelde personen aannemen. Poseer voor de camera of bied ons een pose aan via een foto of de stickman. Vind vervolgens alle kunstwerken waarop een persoon te vinden is waarvan zijn of haar pose overeenkomt met de uwe. Herken uzelf in prachtige kunstwerken op een volledig nieuwe manier!
`,
"home works": `
## Hoe werkt het?

- **Stap 1**: Kies een methode waarmee u een pose aanbiedt aan ons.
  - *Camera*. Deze geeft u de mogelijkheid om live een foto te nemen waarop uzelf en eventueel nog anderen een specifieke pose aannemen. 
  - *Upload*. Hier kan u ons een foto die zich reeds op uw apparaat bevindt aanbieden. De foto moet 1 of meerdere personen bevatten die de houding aannemen waarnaar u wil zoeken. 
  - *Stickman*. Dit is een getekend mannetje waarvan u zelf de pose kan aanpassen door zijn armen, benen en romp zelf te verslepen. 
- **Stap 2**: Laad de pose in via de gekozen methode. Indien er meerdere personen op de ingeladen foto staan heeft u de keuze om te kiezen voor de pose van één enkele persoon door simpelweg deze persoon aan te klikken. Laat ons vervolgens het nodige zoekwerk verrichten en wacht het resultaat af.
- **Stap 3**: Bekijk het resultaat! Scrol doorheen de kunstwerken waarop een persoon dezelfde pose als u aanneemt. Kom meer te weten over het kunstwerk en de artiest door op het kunstwerk te klikken!

Voor wie er niet genoeg van kan krijgen: u kan ook de poses van andere personen op het geselecteerde kunstwerk opzoeken en vergelijken door deze persoon op het kunstwerk aan te klikken.      
`,
"home works bts": `
## Hoe werkt het achter de schermen?

Achter deze website schuilt een grote database waarin alle kunstwerken van de Koninklijke Musea voor Schone Kunsten van België opgeslagen liggen. Naast de artist, het jaartal enzoverder wordt voor elk kunstwerk ook bijgehouden welke pose ze precies aannemen.
Wanneer u ons een foto, afbeelding of bewogen stickman aanbiedt, achterhalen wij welke pose door de persoon wordt aangenomen. Hiervoor gebruiken we een reeds bestaand pose estimation model genaamd MMPose. 

Tot slot vergelijken we de gedetecteerde pose en actie met de reeds opgeslagen poses op de kunstwerken aan de hand van een zelfgeschreven matching algoritme. De kunstwerken waarop de beste matches te vinden zijn worden als resultaat teruggegeven en krijgt u te zien. 
`,
"home focus art": `
## Focus op kunst

Het doel van ons project is in de eerste plaats om het zoeken van kunstwerken makkelijker, aangenamer en toegankelijker te maken. Omdat tekst en woorden hierbij vaak tekort schieten zijn we zeer tevreden deze alternatieve manier aan te bieden. Daarnaast kan onze toepassing een museumbezoek verrijken door op een leuke en vlotte manier interactie met de kunstwerken te voorzien.

We willen deze website ook gebruiken om de kunstwerken zelf meer in het daglicht te zetten. Door bij elk kunstwerk de nodige informatie te voorzien hopen van deze website een leerrijke ervaring te maken. Op die manier zetten we hopelijk mensen aan tot een sterkere interesse voor en beter begrip van de kunstwerken.
`,
"home who are we": `
## Wie zijn wij?

Wij zijn een team van 4 studenten (Liam, Besar, Toon en Wannes) burgerlijk ingenieur computerwetenschappen aan de Universiteit Gent. We voerden dit project uit in het kader van het vak Vakoverschrijdend project uit de derde bachelor. Doorheen het ontwikkelingsproces hebben we waardevolle hulp gekregen van Dieter de Witte (Promotor), Ravi Khatri (Begeleider) en Kenzo Milleville (Begeleider) van IDLab. Een speciale dank gaat uit naar de Koninklijke Musea voor Schone Kunsten van België die dit project ondersteunden! 
`,
"home disclaimer": `
## Disclaimer

De informatie die u ons aanbiedt via een live foto, een ingeladen afbeelding of de stickman wordt niet opgeslagen en kan op geen enkele manier herbruikt worden.
`, 
"load more": "Laad meer",
"info persons": "Klik op een andere persoon om andere poses op te zoeken. Als er geen rode rechthoek verschijnt wanneer je over een persoon beweegt, wil dat zeggen dat het AI model hier geen persoon heeft gedetecteerd.",
"info left artwork": "Klik op de volledige kunstwerken (links) om er meer over te leren, en op dit kunstwerk te zoeken.",
"search": "Zoeken",
"upload": "Upload afbeelding/foto",
"new picture": "Nieuwe foto maken",
"picture": "Foto nemen",
"stickman h": "Klik met de rechtermuisknop op een bolletje om er geen rekening mee te houden bij het zoeken.",
"larger view": "Grotere weergave",
"smaller view": "Kleinere weergave",
"skeleton": "Skelet",
"arms_crossed": "Armen gekruist",
"horse_riding": "Paardrijden",
"jesus_cross": "Jesus aan het kruis",
"kneeling_praying_left": "Knielend bidden (links)",
"kneeling_praying_right": "Knielend bidden (rechts)",
"laying_down_left": "Liggen (links)",
"laying_down_right": "Liggen (rechts)",
"sitting_left": "Zitten (links)",
"sitting_right": "Zitten (rechts)",
"standing_praying": "Staand bidden",
"t_pose": "T-pose"
    },
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'nl', // Default language
    fallbackLng: 'en', // Fallback language
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;